import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const MOBILE_BREAKPOINT = '992px';

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: 1fr auto auto auto 1fr;
  padding: 100px 50px;
  background: linear-gradient(91.42deg, #a1c654 21.18%, #b0e640 98.96%);

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    padding: 50px 20px;
    grid-template-rows: auto auto auto auto 1fr;
  }
`;

const Title = styled.h2`
  grid-column: 1;
  grid-row: 2;
  margin-bottom: 30px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  line-height: 100%;
  color: white;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 1;
    margin-bottom: 45px;
    font-size: 36px;
    text-align: center;
  }
`;

const Subtitle = styled.h3`
  grid-column: 1;
  grid-row: 3;
  margin-bottom: 30px;
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  color: white;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 2;
    margin-bottom: 45px;
    font-size: 18px;
    text-align: center;
  }
`;

const Descriprion = styled.div`
  grid-column: 1;
  grid-row: 4 / 5;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  color: white;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 5;
    text-align: center;
  }
`;

const ImageContainer = styled.div`
  grid-column: 2;
  grid-row: 1 / 6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;

  max-height: 700px;
  max-width: 700px;

  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    grid-column: 1 / 3;
    grid-row: 3;
    margin-left: 0;
    margin-bottom: 45px;
  }
`;

export default function BannerInfoTypeA({
  title,
  subtitle,
  description,
  bgImage,
}) {
  return (
    <Container>
      <Title>{title}</Title>
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : <></>}
      <Descriprion>{description}</Descriprion>
      <ImageContainer>
        <GatsbyImage
          image={bgImage}
          style={{
            borderRadius: '3px',
          }}
        />
      </ImageContainer>
    </Container>
  );
}

import React from 'react';
import { graphql } from 'gatsby';

import HeroTypeC from '../components/Heroes/HeroTypeC';
import BannerInfoTypeB from '../components/Banners/BannerInfoTypeB';
import BannerTypeA from '../components/Banners/BannerTypeA';
import Reviews from '../components/Reviews/Review';
import BannerInfoTypeA from '../components/Banners/BannerInfoTypeA';
import TripleBanner from '../components/Location/TripleBanner';
import MultiArticleV2 from '../components/Articles/MultiArticleV2';
import FaqSection from '../components/FAQ/FaqSection';
import SEO from '../components/SEO/SEO';

export default function Kingston({ data }) {
  const hero_type_c = data.strapiKingstonAbout.Hero;
  const info_banner_type_b = data.strapiKingstonAbout.Info_Banner_Type_B;
  const banner_type_middle = data.strapiKingstonAbout.Blog_Banner_Middle;
  const reviews = data.strapiKingstonAbout.Reviews;
  const info_banner_type_a = data.strapiKingstonAbout.Info_Banner_Type_A;
  const triple_banner = data.strapiKingstonAbout.Triple_Info_Section;
  const multi_article = data.strapiKingstonAbout.Multi_Article_Section;
  const faq = data.strapiKingstonAbout.FAQ;
  const banner_type_bottom = data.strapiKingstonAbout.Blog_Banner_Bottom;

  return (
    <>
      <SEO
        title="Kingston’s Favourite Weed Dispensary | House of Cannabis"
        description="Visit out cannabis store in Kingston, and you’ll find 
            the city’s best selection of cannabis flowers and weed accessories. 
            Not sure what you want? We can help!"
      />
      <HeroTypeC
        title={hero_type_c.Title}
        subtitle={hero_type_c.Content}
        heroImage={
          hero_type_c.BackgroundImage.localFile.childImageSharp.gatsbyImageData
        }
      />
      <BannerInfoTypeB
        description={info_banner_type_b.Content}
        bgImage={
          info_banner_type_b.BackgroundImage.localFile.childImageSharp
            .gatsbyImageData
        }
        buttonLabel={info_banner_type_b.Button.Label}
        buttonLink={info_banner_type_b.Button.Custom_Link_Slug}
        center
        hasGradient
      />
      <BannerTypeA
        title={banner_type_middle.Title}
        description={banner_type_middle.Content}
        bannerImage={
          banner_type_middle.Background_Image.localFile.childImageSharp
            .gatsbyImageData
        }
        buttonLabel="READ MORE"
        link="/kingston/delivery"
      />
      {/* <Reviews 
          title={reviews.Title}
          reviewsdata={reviews.Single_Review}
      /> */}
      <BannerInfoTypeA
        title={info_banner_type_a.Title}
        description={info_banner_type_a.Content}
        bgImage={
          info_banner_type_a.Image.localFile.childImageSharp.gatsbyImageData
        }
      />
      <TripleBanner
        title={triple_banner.Title}
        banners={triple_banner.Single_Triple_Info_Banner}
      />
      <MultiArticleV2
        leftarticle={multi_article.Left_Mutli_Article}
        rightarticle={multi_article.Right_Multi_Article}
      />
      <FaqSection faqdata={faq.FAQs} title={faq.Title} />
      <BannerTypeA
        title={banner_type_bottom.Article_Link.Title}
        description={banner_type_bottom.Article_Link.Preview_Text}
        bannerImage={
          banner_type_bottom.Article_Link.BannerImage.localFile.childImageSharp
            .gatsbyImageData
        }
        link="/menu"
        buttonLabel="READ ARTICLE"
      />
    </>
  );
}
/* {`/blog/${banner_type_bottom.Article_Link.slug}`} */

export const query = graphql`
  query {
    strapiKingstonAbout {
      Hero {
        Title
        Content
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Info_Banner_Type_B {
        Content
        Button {
          Label
          Custom_Link_Slug
        }
        BackgroundImage {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Blog_Banner_Middle {
        Title
        Blog_Button_Link {
          Link_Slug
          Button_Label
        }
        Content
        Background_Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      Reviews {
        Title
        id
        Single_Review {
          Quote
          Number_Of_Stars
          Date
          By
        }
      }
      Info_Banner_Type_A {
        Content
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        Title
      }
      Triple_Info_Section {
        Title
        Single_Triple_Info_Banner {
          Content
          Header
          Image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
      Multi_Article_Section {
        Left_Mutli_Article {
          article {
            Title
            Preview_Text
            slug
            PublishedDate
            BannerImage {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        Right_Multi_Article {
          article {
            Title
            Preview_Text
            slug
            PublishedDate
          }
        }
      }
      FAQ {
        Title
        FAQs {
          Question
          Link_Slug
          Link_Label
          Answer
        }
      }
      Blog_Banner_Bottom {
        Article_Link {
          Preview_Text
          Title
          slug
          BannerImage {
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100)
              }
            }
          }
        }
      }
    }
  }
`;

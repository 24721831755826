import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';


function SingleBanner({banner, index}){
    const BannerContent = styled.div`
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding: 20px 20px;
        h3{
            color: var(--darkgreen);
            font-family: Oswald;
            font-size: 4rem;
        }
        p{
            text-align: left;
        }
        @media (max-width: 900px){
            p{
                text-align: center;
            }
            h3{
                text-align: center;
            }
        }
    `;

    const SingleBanner = styled.div`
        margin-bottom: 25px;
        background-color: white;
        .banner-image{
            width: 100%;
        }
    `;

    return(
        <SingleBanner className="row">
            <div className={ (index % 2 == 0) 
                                ? "col-lg-6 col-12 p-0 order-lg-1 order-1"
                                : "col-lg-6 col-12 p-0 order-lg-2 order-1"
                            }>
                <GatsbyImage 
                    image={banner.Image.localFile.childImageSharp.gatsbyImageData} 
                    className="banner-image" 
                />
            </div>
            <div className={ (index % 2 != 0) 
                                ? "col-lg-6 col-12 p-0 order-lg-1 order-1"
                                : "col-lg-6 col-12 p-0 order-lg-2 order-1"
                            }>
                <BannerContent>
                    <div>
                        <h3>{banner.Header}</h3>
                        <p>{banner.Content}</p>
                    </div>
                </BannerContent>
            </div>
        </SingleBanner>
    );
}

export default function TripleBanner({title, banners}){

    const Title = styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
        h2{
            color: black;
        }
        padding-top: 50px;
        padding-bottom: 70px;

        @media(max-width: 1000px){
            h2{
                width: 100%;
                text-align: center;
            }
        }
    `;


    return(
        <>
            <Title><h2>{title}</h2></Title>
            <div className="container">
                {banners.map((banner, index) => (
                    <SingleBanner banner={banner} index={index}/>
                ))}
            </div>
        </>
    );
}
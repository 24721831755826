import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

const MOBILE_BREAKPOINT = '992px';

const Wrapper = styled.div`
  margin: 70px 20px;

  .gradient {
    background: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.75) 50%,
      rgba(0, 0, 0, 0.5) 100%
    );
  }

  .bg-image,
  .bg-image::after,
  .bg-image::before {
    min-height: 58vh;
    background-size: cover;
    @media (max-width: ${MOBILE_BREAKPOINT}) {
      width: 100%;
      height: 100%;
      min-height: 105vh;
      background-size: 450% 100%;
    }
  }
`;

const Container = styled.div`
  padding: 47px 80px;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
  align-items: center;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .hoc-button {
    margin-top: 70px;
    display: inline-block;
    padding: 20px 3rem;
  }
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    min-height: 420px;
    padding: ${(props) =>
      props.center ? '50px 40px 60px' : '30px 100px 140px 30px'};
  }
`;

const Description = styled.div`
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  color: white;
  opacity: 1;
  @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
    font-size: 15px;
  }
  h2 {
    color: white;
    opacity: 1;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
      line-height: 40px;
    }
  }
`;

export default function BannerInfoTypeB({
  description,
  bgImage,
  buttonLabel,
  buttonLink,
  center,
  hasGradient = false,
}) {
  let b_label = 'SHOP ONLINE';
  if (buttonLabel) {
    b_label = buttonLabel;
  } else {
    b_label = 'SHOP ONLINE';
  }

  return (
    <Wrapper>
      <BgImage
        image={bgImage}
        preserveStackingContext
        className={`bg-image ${hasGradient ? ' gradient' : ''}`}
        style={{
          width: '',
          height: '',
          backgroundPosition: 'center',
          borderRadius: '3px',
          overflow: 'hidden',
          backgroundSize: 'cover',
        }}
      >
        <Container center={center}>
          <Description center={center}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]} children={description} />
          </Description>
          <Link to={`/${buttonLink}`} className="hoc-button">
            {b_label}
          </Link>
        </Container>
      </BgImage>
    </Wrapper>
  );
}

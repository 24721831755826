import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { BsStarFill, BsStarHalf } from 'react-icons/bs';

SwiperCore.use([Pagination]);

function ReviewStars({ stars }) {
  const starsArr = [];
  for (let i = 0; i < Math.floor(stars); i++) {
    starsArr.push(<BsStarFill />);
  }
  if (Math.floor(stars) != stars) {
    starsArr.push(<BsStarHalf />);
  }
  return <div className="stars">{starsArr}</div>;
}

function ReviewCard({ reviewdata }) {
  const Card = styled.div`
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 40px;
    min-height: 35vh;
    p {
      text-align: center;
    }
    .starswrapper {
      justify-content: center;
      color: var(--darkgreen);
    }
    .author {
      font-family: Oswald;
      font-weight: bold;
      margin: 10px 0px;
    }
    .date {
      opacity: 0.6;
    }
  `;

  return (
    <Card>
      <p>{reviewdata.Quote}</p>
      <div className="starswrapper">
        <ReviewStars stars={reviewdata.Number_Of_Stars} />
      </div>
      <div className="author">{reviewdata.By}</div>
      <div className="date">{reviewdata.Date}</div>
    </Card>
  );
}

export default function Reviews({ title, reviewsdata }) {
  const Wrapper = styled.div`
    padding: 30px 50px;
    margin: 30px 0px;
  `;

  const Title = styled.h2`
    text-align: center;
    margin: 35px 20px;
  `;

  return (
    <Wrapper>
      <Title>{title}</Title>
      <Swiper
        spaceBetween={10}
        slidesPerView={1}
        breakpoints={{
          576: {
            slidesPerView: 1,
          },
          892: {
            slidesPerView: 2,
          },
          1000: {
            slidesPerView: 3,
          },
        }}
      >
        {reviewsdata.map((review, index) => (
          <SwiperSlide virtualIndex={index}>
            <ReviewCard reviewdata={review} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Wrapper>
  );
}
